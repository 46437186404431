<template>

    <div>

        <b-modal id="reqvideoModal" centered>

            <div class="modalTitle">

                <h2>Video Request</h2>

                <span class="closeModal">

                    <span class="icon-close"></span>

                </span>

            </div>

            <div class="modalContent videoRequest pt-3">

                <p>To request for the professional video shots of your property, Please fill below form</p>

                <div class="form-group mb-3">

                    <label>Name</label>

                    <input type="text" class="univ-input" name="" id="">

                </div>

                <div class="form-group mb-3">

                    <label>Contact Number</label>

                    <input type="text" class="univ-input" name="" id="">

                </div>

                <div class="form-group mb-3">

                    <label>Email Address</label>

                    <input type="text" class="univ-input" name="" id="">

                </div>

                <div class="form-group mb-3">

                    <button class="univ-btn w-100">Submit</button>

                </div>

                <div class="info-disclaimer">

                    <p>We'll contact you for the video shoot after submiting form</p>

                </div>

            </div>

        </b-modal>

    </div>

</template>

<script>

export default {

    name: 'RequestVideoModal'

}

</script>