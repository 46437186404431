<template>
    <section class="page-wrap pb-5">
        <div class="inner-banner">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>Add Property</h1>
                    </div>
                    <div class="col-12">
                        <div class="breadcrumb">
                            <ul>
                                <li>
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li>
                                    Add Property
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>        
        </div>
        <div class="container">
            <div class="row">
                <AddForm />
                <AddImageBanner />
            </div>
        </div>
        <RequestVideoModal />
    </section>
</template>
<script>
import AddForm from './partials/AddForm.vue';
import AddImageBanner from './partials/AddImageBanner.vue';
import RequestVideoModal from './partials/RequestVideoModal.vue'
export default {
    name: "AddProperty",
    components: { AddImageBanner, AddForm, RequestVideoModal }
}
</script>
<style src="../../assets/css/property.css"></style>