<template>
    <div class="col-xl-4">
        <div class="addimage-banner imagebanner-area">
            <div class="no-image-area">
                <span class="icon-image"></span>
                <h3>There is no any image, please upload</h3>
                <p>Add up to 20 photos. More photos get more replies!</p>
                <h6 class="brand-color">Drag & drop image here</h6>
            </div>
            <ul>
                <li>
                    <div class="file-input upload-btn">
                        <input
                          type="file"
                          name="file-input"
                          id="file-input"
                          class="file-input__input"
                        />
                        <label class="file-input__label" for="file-input">
                            <span class="icon-upload"></span>
                            <span>Upload Image</span></label
                        >
                      </div>
                </li>
                <li>
                    <div class="video-request" v-b-modal.reqvideoModal>
                        <span class="icon-video"></span>
                        Request for Video
                    </div>
                </li>
            </ul>
            <button class="univ-btn w-100">Submit Property</button>
        </div>
    </div>
            
</template>
<script>
export default {
    name: 'AddImageBanner'
}
</script>