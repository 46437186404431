<template>
    <div class="col-xl-8">
        <div class="addForms display-card p-4">
            <h3>Select Categories</h3>
            <div class="form-group row">
                <div class="col-xl-6 mb-3">
                    <label>Category</label>
                    <select name="" id="" class="univ-input">
                        <option value="">None</option>
                        <option value="">Home</option>
                        <option value="">Apartment</option>
                        <option value="">Land</option>
                        <option value="">Residence</option>
                    </select>
                </div>
                <div class="col-xl-6 mb-3">
                    <label>Listed On</label>
                    <select name="" id="" class="univ-input">
                        <option value="">None</option>
                        <option value="">Buy</option>
                        <option value="">Rent</option>
                    </select>
                </div>
            </div>

            <hr>

            <h3>Property Description</h3>
            <div class="form-group row">
                <div class="col-12 mb-3">
                    <label>Title <span class="required">*</span></label>
                    <input type="text" class="univ-input" placeholder="Enter Property Title" name="" id="">
                </div>
                <div class="col-12 mb-3">
                    <label>Description</label>
                    <textarea name="" id="" class="univ-input" rows="4"></textarea>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-xl-4 mb-3">                                
                    <label>Address <span class="required">*</span></label>
                    <input type="text" class="univ-input" placeholder="Enter Address" name="" id="">
                </div>
                <div class="col-xl-4 mb-3">                                
                    <label>District <span class="required">*</span></label>
                    <select name="" id="" class="univ-input">
                        <option value="null">Select District</option>
                        <option value="">Kathmandu</option>
                        <option value="">Bhaktapur</option>
                        <option value="">Lalitpur</option>
                        <option value="">Kavrepalanchowk</option>
                        <option value="">Chitwan</option>
                    </select>
                </div>
                <div class="col-xl-4 mb-3">                                
                    <label>State / Pradeesh <span class="required">*</span></label>
                    <select name="" id="" class="univ-input">
                        <option value="null">Select Pradesh</option>
                        <option value="">Pradesh 1</option>
                        <option value="">Pradesh 2</option>
                        <option value="">Pradesh 3</option>
                        <option value="">Pradesh 4</option>
                        <option value="">Pradesh 5</option>
                    </select>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-xl-3 mb-3">
                    <label>Beds</label>
                    <select name="" id="" class="univ-input">
                        <option value="">Select Number</option>
                        <option value="">1</option>
                        <option value="">2</option>
                        <option value="">3</option>
                        <option value="">4</option>
                        <option value="">5</option>
                    </select>
                </div>
                <div class="col-xl-3 mb-3">
                    <label for="">Bath Room</label>
                    <select name="" id="" class="univ-input">
                        <option value="">Select Number</option>
                        <option value="">1</option>
                        <option value="">2</option>
                        <option value="">3</option>
                        <option value="">4</option>
                        <option value="">5</option>
                    </select>
                </div>
                <div class="col-xl-3 mb-3">
                    <label for="">Kitchen</label>
                    <select name="" id="" class="univ-input">
                        <option value="">Select Number</option>
                        <option value="">1</option>
                        <option value="">2</option>
                        <option value="">3</option>
                        <option value="">4</option>
                        <option value="">5</option>
                    </select>
                </div>
                <div class="col-xl-3 mb-3">
                    <label for="">Built Year</label>
                    <input type="" class="univ-input" name="" id="" placeholder="eg: 2050">
                </div>
                <div class="col-xl-3 mb-3">
                    <label for="">Air Conditioner</label>
                    <select name="" id="" class="univ-input">
                        <option value="">Select</option>
                        <option value="">Yes</option>
                        <option value="">No</option>
                    </select>
                </div>
                <div class="col-xl-3 mb-3">
                    <label for="">Garage</label>
                    <select name="" id="" class="univ-input">
                        <option value="">Select</option>
                        <option value="">Yes</option>
                        <option value="">No</option>
                    </select>
                </div>
            </div>

            <hr>

            <h3>Features</h3>
            <div class="form-group featuredbox">
                <ul>
                    <li>
                        <input class="styled-checkbox" id="feature1" type="checkbox" value="value1">
                        <label for="feature1">Feature</label>
                    </li>
                    <li>
                        <input class="styled-checkbox" id="feature1" type="checkbox" value="value1">
                        <label for="feature1">Feature</label>
                    </li>
                    <li>
                        <input class="styled-checkbox" id="feature1" type="checkbox" value="value1">
                        <label for="feature1">Feature</label>
                    </li>
                    <li>
                        <input class="styled-checkbox" id="feature1" type="checkbox" value="value1">
                        <label for="feature1">Feature</label>
                    </li>
                    <li>
                        <input class="styled-checkbox" id="feature1" type="checkbox" value="value1">
                        <label for="feature1">Feature</label>
                    </li>
                    <li>
                        <input class="styled-checkbox" id="feature1" type="checkbox" value="value1">
                        <label for="feature1">Feature</label>
                    </li>
                </ul>
            </div>

            <hr>

            <h3>Set Map</h3>
            <div class="form-group mb-3">
                <input type="text" class="univ-input">
            </div>
            <div class="form-group mb-3">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3594654.5398182087!2d81.4896619866037!3d28.376838403492822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3995e8c77d2e68cf%3A0x34a29abcd0cc86de!2sNepal!5e0!3m2!1sen!2snp!4v1687071086045!5m2!1sen!2snp" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <div class="form-group d-flex align-items-center mb-3">
                <label class="switch mb-0 me-2">
                    <input type="checkbox">
                    <span class="slider round"></span>
                </label>
                <p class="mb-0">Switch to Add for Premium Listing</p>
            </div>
            <div class="form-group row">
                <div class="col-xl-6 mb-3">
                    <select name="" id="" class="univ-input" >
                        <option value="null">Select Listing Type</option>
                        <option value="premium">Premium</option>
                        <option value="featured">Featured</option>
                        <option value="normal">Normal</option>
                    </select>
                </div>
                <div class="col-xl-6 mb-3" >
                    <date-picker type="datetime" placeholder="Enter End Date"></date-picker>
                </div>
            </div>
        </div>                    
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    name: 'AddForm',
    components: { DatePicker },
}
</script>